import { isTauri } from "@tauri-apps/api/core";
import { getCurrentWindow, Window } from "@tauri-apps/api/window";

let isNative: boolean = false;
let isMain: boolean = false;
let currentWindow: Window | null = null;

export function IsNative() {
  return isNative;
}

export function IsMainWindow() {
  return isMain;
}

export function nativeSetup() {
  isNative = isTauri();
  if (!isNative) {
    return;
  }

  currentWindow = getCurrentWindow();
  isMain = currentWindow.label === "main";
}

export function setDecoration(enabled: boolean) {
  currentWindow?.setDecorations(enabled);
}
