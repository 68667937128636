import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { ServiceWorkerModule } from "@angular/service-worker";
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from "@coreui/angular";
import { ChartsModule } from "ng2-charts";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxEchartsModule } from "ngx-echarts";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { DefaultLayoutComponent } from "./containers";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { IconsModule } from "./views/icons/icons.module";
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppTranslateLoader } from './app-translate-loader';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { PixelModule } from 'ngx-pixel';
import { WithLoadingModule } from "../pipe/withLoading/withLoading.module";


const APP_CONTAINERS = [DefaultLayoutComponent];

export function chartModule(): any {
  return import("echarts");
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    NgSelectModule,
    ChartsModule,
    ModalModule.forRoot(),
    /* ServiceWorkerModule.register("./service-worker.js", {
      enabled: true,
      registrationStrategy: "registerImmediately",
    }), */
    NgxSpinnerModule.forRoot({
      type: 'ball-clip-rotate-multiple',
    }),
    FormsModule,
    HttpClientModule,
    IconsModule,
    NgxEchartsModule.forRoot({
      echarts: chartModule,
    }),
    NgxGoogleAnalyticsModule.forRoot('G-M6EEVW6C7J'),
    NgxGoogleAnalyticsRouterModule,
    WithLoadingModule,
    PixelModule.forRoot({ enabled: true, pixelId: '699943184691236' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
      }
    })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    // LoginComponent,
    // RegisterComponent,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
